import {CommonConstants} from "../config/common_constants";

export const Constants = {
    /**********************************跳转原生相关*************************************************/
    // coinX 的主页
    lookCoinX: `${CommonConstants.BASE_H5_PAGE_URL}`,
    /**********************************存储localStorage 的key*************************************************/
    userMobile: "userMobile",//用户手机号
    accessToken: "accessToken",//授权token
    userNum: "userNum",//授权token
    /**********************************资产类型名称*************************************************/
    COIN_X: "X币",
    /**********************************资产类型*************************************************/
    X_COIN: "20156",
    POWER_TYPE: "20157",
    Z_COIN_KE_YONG: "2016",//可用
    /**********************************toast一些常量*************************************************/
    OPEN_SOON: "即将开放",

    TWO_NUMBER: 2,
    EIGHT_NUMBER: 8,
    THREE_NUMBER: 3,

    R_COIN: "20160",
    R_COIN_COIN_X: "20165",//在coinX里面查火箭值用的资产
    R_D_COIN: "20159",//冻结火箭值
    R_VALUE: "火箭值",

    puTong: "普通红包",
    HUO_LI_ZHI: "2012",
    pinShouQi: "拼手气红包",
    kouLing: "口令红包",

    X_COIN_NAME: "X",
    DOG_COIN: "狗狗币",
    DOG_COIN_TYPE: "20166",
    SHI_COIN: "SHIB",
    SHI_COIN_TYPE: "20167",
    WEN_COIN: "WEN",
    WEN_COIN_TYPE: "20168"
}
