import {EncryptConstants} from "../config/encrypt_constants";
import {Constants} from "@/utils/constants";

export default {
    uuidMiddle() {
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        // uuid 中间无-
        s[8] = s[13] = s[18] = s[23] = "";
        var uuid = s.join("");
        return uuid;
    },
    //登录密码加密
    getEncryptLoginPwd(password) {
        let passwordTemp = password.trim()
        let sha256 = require("js-sha256").sha256; //sha256.hmac(密钥,密码)
        return sha256.hmac(EncryptConstants.sha256Key, passwordTemp)
    },
    //支付密码加密
    getEncryptPayPwd(password) {
        let sha256 = require("js-sha256").sha256; //sha256.hmac(密钥,密码)
        return sha256.hmac(EncryptConstants.sha256Key_deal, password)
    },
    formatDecimal(value) {
        if (value <= 0) {
            return "0"
        }
        return value.toFixed(Constants.EIGHT_NUMBER).replace(/\.?0+$/, '');
    },
    //保留2位小数  后面的0保留去掉
    formatDecimal2NoZero(value) {
        if (value <= 0) {
            return "0"
        }
        return value.toFixed(Constants.TWO_NUMBER).replace(/\.?0+$/, '');
    },
    formatDecimal3(value) {
        if (value <= 0) {
            return "0"
        }
        return value.toFixed(Constants.THREE_NUMBER).replace(/\.?0+$/, '');
    },
    isEmpty(obj) {
        return typeof obj == "undefined" || obj == null || obj == "";
    },
    //将时间转化为2023-3-12
    formatDateForDayAll: function (value) {
        var year = value.getFullYear();
        var month = value.getMonth() + 1; // 月份从0开始，需要加1
        var day = value.getDate();
        var hours = value.getHours();
        var minutes = value.getMinutes();
        var seconds = value.getSeconds();

// 格式化日期和时间
        return year + '-' +
            (month < 10 ? '0' + month : month) + '-' +
            (day < 10 ? '0' + day : day) + ' ' +
            (hours < 10 ? '0' + hours : hours) + ':' +
            (minutes < 10 ? '0' + minutes : minutes) + ':' +
            (seconds < 10 ? '0' + seconds : seconds);

    },
}
